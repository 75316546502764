import { ServerGameActionResponse } from "./../hubs/models/ServerGameActionResponse";
import { LoadingTracker } from "./loadingStore";
import { observable, action, computed, reaction } from "mobx";
import gameHub from "../hubs/GameHub";
import { ServerGameStateModel } from "../hubs/models/ServerGameStateModel";
import { GamePhase, GameMode } from "../hubs/models/GamePhase";
import notificationStore from "./notificationStore";
import { TeamsGameRound, IndividualsGameRound, Round } from "../hubs/models/Round";
import { computedFn } from "mobx-utils";

const defaultServerGameState: ServerGameStateModel = {
  id: "",
  gameMode: GameMode.Teams,
  actionCountdown: null,
  phase: GamePhase.Setup,
  players: [],
  areTeamsValid: false,
  round: null,
  history: [],
  team1Score: 0,
  team2Score: 1,
};

class GameStateStore {
  constructor() {
    gameHub.onServerGameStateUpdated.subscribe(this.handleServerGameStateUpdated);
    gameHub.onYouveBeenBooted.subscribe(this.handleYouveBeenBooted);
    gameHub.onNotification.subscribe((message) => notificationStore.info(message));
    gameHub.onReconnected.subscribe(() => {
      if (this.playerName && this.gameId) {
        this.joinGame(this.playerName, this.gameId);
      }
    });

    this.playerName = sessionStorage.getItem("playerName");

    const inviteGameId = window.location.pathname.slice(1);
    if (inviteGameId) {
      this.inviteGameId = window.location.pathname.slice(1);

      if (this.playerName && inviteGameId) {
        this.joinGame(this.playerName, inviteGameId);
      }
    } else {
      this.gameId = sessionStorage.getItem("gameId");

      if (this.playerName && this.gameId) {
        this.joinGame(this.playerName, this.gameId);
      }
    }

    reaction(
      () => this.clientGuess,
      () => {
        if (this.clientGuess !== null && this.gameId) {
          gameHub.setGuess(this.gameId!, this.clientGuess!);
        }
      },
      { delay: 50 }
    );
  }

  loadingTracker = new LoadingTracker();
  @observable serverState: ServerGameStateModel = defaultServerGameState;
  @observable gameId: string | null = null;
  @observable playerName: string | null = null;

  @observable inviteGameId?: string;
  @observable clientGuess: number = 0;
  @observable gameHub = gameHub;
  @observable target: number | null = null;
  @observable guess: number = 0;

  @computed get phase() {
    return this.serverState.phase;
  }

  @computed get gameMode() {
    return this.serverState.gameMode;
  }

  @computed get players() {
    return this.serverState.players;
  }

  @computed get team1Score() {
    return this.serverState.team1Score;
  }

  @computed get team2Score() {
    return this.serverState.team2Score;
  }

  @computed get areTeamsValid() {
    return this.serverState.areTeamsValid;
  }

  @computed get round() {
    return this.serverState.round;
  }

  @computed get history() {
    return this.serverState.history;
  }

  @computed get scoringZoneAngle() {
    return (this.target ?? 0) * 83;
  }

  @computed get pointerAngle() {
    return this.guess * 83;
  }

  @computed get canGuess() {
    let hasFinalizedGuess = false;
    if (this.player && this.serverState.gameMode === GameMode.Individuals) {
      const round = this.serverState.round as IndividualsGameRound;
      hasFinalizedGuess = this.getPlayerGuess(this.playerName!, round)?.isLocked ?? false;
    }
    return this.serverState.phase === GamePhase.Team && this.isPlayerOnGuessingTeam && !this.isPlayerPsychic && !hasFinalizedGuess;
  }

  @computed get isScreenOpen() {
    return (!!this.target && (this.serverState.phase === GamePhase.Scoring || this.serverState.phase === GamePhase.GameOver)) || this.isPlayerPsychic;
  }

  @computed get isInGame() {
    return !!this.gameId;
  }

  @computed get player() {
    return this.serverState.players.find((p) => p.name === this.playerName);
  }

  @computed get plyaerTeam() {
    return this.player?.team ?? null;
  }

  @computed get isPlayerHost() {
    return this.player?.isHost;
  }

  @computed get psychicPlayer() {
    return this.serverState.players.find((p) => p.isPsychic);
  }

  @computed get psychicPlayerName() {
    return this.psychicPlayer?.name;
  }

  @computed get isPlayerPsychic() {
    return this.serverState.players.find((p) => p.name === this.playerName)?.isPsychic ?? false;
  }

  @computed get isPlayerOnGuessingTeam() {
    const player = this.serverState.players.find((p) => p.name === this.playerName);
    const psychic = this.serverState.players.find((p) => p.isPsychic);
    return player?.team === psychic?.team;
  }

  @computed get activeTeam() {
    const psychicPlayerTeam = this.psychicPlayer?.team;
    if (psychicPlayerTeam === undefined) return null;

    if (this.serverState.phase === GamePhase.Psychic || this.serverState.phase === GamePhase.Team) {
      return psychicPlayerTeam;
    }

    if (this.serverState.phase === GamePhase.LeftRight) {
      return psychicPlayerTeam === 1 ? 2 : 1;
    }

    return false;
  }

  calculatePointerAngle = computedFn((guess: number) => {
    return guess * 83;
  });

  @computed get guessLockedCount() {
    const round = this.serverState.round as IndividualsGameRound;
    return Object.values(round?.playerGuesses).filter((pg) => pg.isLocked).length ?? 0;
  }

  @computed get actionCountdown() {
    return this.serverState.actionCountdown;
  }

  getPlayerGuess(playerName: string | null, round: Round | null) {
    if (!playerName) return { guess: 0, isLocked: false };

    const individualsGameRound = round as IndividualsGameRound;
    if (!individualsGameRound || !individualsGameRound?.playerGuesses) return { guess: 0, isLocked: false };
    const playerNameKey = Object.keys(individualsGameRound?.playerGuesses).find((k) => k.toLowerCase() === playerName.toLowerCase());

    if (playerNameKey) {
      return individualsGameRound?.playerGuesses[playerNameKey];
    }

    return { guess: 0, isLocked: false };
  }

  @action.bound setGuess(guess: number) {
    this.clientGuess = guess;
  }

  @action.bound setPlayerName(playerName: string | null) {
    this.playerName = playerName;
    if (playerName === null) sessionStorage.removeItem("playerName");
    else sessionStorage.setItem("playerName", playerName);
  }

  @action.bound setGameId(gameId: string | null) {
    this.gameId = gameId;
    if (gameId === null) sessionStorage.removeItem("gameId");
    else sessionStorage.setItem("gameId", gameId);
  }

  @action.bound async createGame(playerName: string, gameMode: GameMode) {
    await this.executeHubAction("create game", async () => {
      const response = await gameHub.createGame(playerName, gameMode);

      if (response.isSuccess) {
        console.log("created game: " + response.gameState!.id);

        this.setPlayerName(playerName);
        window.history.replaceState({}, `Wavelength Game - ${this.gameId}`, `/${response.gameState!.id}`);
      }
      return response;
    });
  }

  @action.bound async joinGame(playerName: string, gameId: string) {
    this.setPlayerName(playerName);
    await this.executeHubAction("join game", async () => {
      try {
        const response = await gameHub.joinGame(playerName, gameId);

        if (response.isSuccess) {
          console.log("joined game: " + response.gameState!.id);

          this.setGameId(gameId);
          window.history.replaceState({}, `Wavelength Game - ${this.gameId}`, `/${response.gameState!.id}`);
        }
        return response;
      } catch (e) {
        this.setGameId(null);
        this.setPlayerName(null);
        this.inviteGameId = undefined;
        window.history.replaceState({}, `Wavelength Game`, "/");
        throw e;
      }
    });
  }

  @action.bound async leaveGame() {
    await this.executeHubAction("leave the game", () => gameHub.leaveGame(this.gameId!));
    this.setGameId(null);
  }

  @action.bound async joinTeam(teamId: number) {
    await this.executeHubAction("join a team", () => gameHub.joinTeam(this.gameId!, teamId));
  }

  @action.bound async leaveTeam() {
    await this.executeHubAction("leave your team", () => gameHub.leaveTeam(this.gameId!));
  }

  @action.bound async startGane() {
    await this.executeHubAction("start the game", () => gameHub.startGame(this.gameId!));
  }

  @action.bound async startNextRound() {
    await this.executeHubAction("start the next round", () => gameHub.startNextRound(this.gameId!));
  }

  @action.bound async startNewGame() {
    await this.executeHubAction("start a new game", () => gameHub.startNewGame(this.gameId!));
  }

  @action.bound async setClue(clue: string) {
    await this.executeHubAction("give the clue", () => gameHub.setClue(this.gameId!, clue));
  }

  @action.bound async finalizeGuess() {
    await this.executeHubAction("finalize your guess", () => gameHub.finalizeGuess(this.gameId!));
  }

  @action.bound async cancelGuess() {
    await this.executeHubAction("cancel the guess", () => gameHub.cancelGuess(this.gameId!));
  }

  @action.bound async guessLeftRight(direction: string) {
    await this.executeHubAction("guess left or right", () => gameHub.guessLeftRight(this.gameId!, direction));
  }

  @action.bound async cancelLeftRightGuess() {
    await this.executeHubAction("cancel the left/right guess", () => gameHub.cancelLeftRightGuess(this.gameId!));
  }

  @action.bound async bootPlayer(playerName: string) {
    await this.executeHubAction("boot player", () => gameHub.bootPlayer(this.gameId!, playerName));
  }

  @action.bound async handleServerGameStateUpdated(serverGameState: ServerGameStateModel) {
    this.serverState = serverGameState;
    this.setGameId(serverGameState.id);

    if (this.phase === GamePhase.Psychic && !this.isPlayerPsychic) {
      this.target = null;
    }

    if (this.isPlayerPsychic && (this.target === null || this.phase === GamePhase.Psychic)) {
      this.target = await gameHub.getTarget(this.gameId!);
    }

    const publicTarget = serverGameState.round?.target.public;
    if (publicTarget !== undefined && publicTarget !== null) {
      this.target = publicTarget;
    }

    if (this.serverState.gameMode === GameMode.Teams) this.guess = (this.serverState.round as TeamsGameRound)?.guess;
    if (this.serverState.gameMode === GameMode.Individuals) this.guess = this.getPlayerGuess(this.playerName, this.serverState.round)?.guess;
  }

  @action.bound handleYouveBeenBooted() {
    notificationStore.warning("You've been booted for being naughty");
    this.setGameId(null);
    this.setPlayerName(null);
    this.inviteGameId = undefined;
  }

  private async executeHubAction(actionName: string, hubAction: () => Promise<ServerGameActionResponse | undefined>) {
    await this.loadingTracker.track(async () => {
      try {
        const response = await hubAction();
        if (!!response) {
          if (response.isSuccess) {
            if (response.gameState) {
              this.handleServerGameStateUpdated(response.gameState);
            }
          } else {
            notificationStore.error(response.message!);
          }
        }
      } catch (error) {
        console.error(error);
        notificationStore.error("An error ocurrred attempting to " + actionName);
      }
    });
  }
}

const gameState = new GameStateStore();

(window as any).gameState = gameState;

export default gameState;
