import { observable, action, computed } from "mobx";

export class LoadingTracker {
  @observable private activePromises = 0;

  constructor() {
    this.activePromises = 0;
  }

  @action.bound async track<T>(promiseAction: () => Promise<T>) {
    this.activePromises++;

    await promiseAction()
      .then(() => {
        this.activePromises--;
      })
      .catch(() => {
        this.activePromises--;
      });
  }

  @computed get isComplete() {
    return this.activePromises === 0;
  }

  @computed get isBusy() {
    return this.activePromises !== 0;
  }
}
