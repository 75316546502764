import React from "react";
import gameState from "./store/gameState";
import { observer } from "mobx-react";
import { GamePhase, GameMode } from "./hubs/models/GamePhase";
import Dial, { PointerProps } from "./Dial";
import { IndividualsGameRound } from "./hubs/models/Round";

const DialFromGameState: React.FC = observer(() => {
  const isScoringZoneVisible = gameState.phase === GamePhase.Scoring || gameState.phase === GamePhase.GameOver || gameState.isPlayerPsychic || false;
  const pointers: PointerProps[] = [];
  if (gameState.gameMode === GameMode.Individuals && (gameState.isPlayerPsychic || gameState.phase === GamePhase.Scoring || gameState.phase === GamePhase.GameOver)) {
    const round = gameState.round as IndividualsGameRound;
    if (round) {
      gameState.players
        .filter((p) => !p.isPsychic && gameState.player !== p)
        .forEach((player) => {
          pointers.push({
            angle: gameState.calculatePointerAngle(gameState.getPlayerGuess(player.name, round)?.guess ?? 0),
            colour: "rgb(211,38,54, 0.5)",
            secondaryColour: "rgb(167,30,43, 1)",
            label: player.name,
          });
        });
    }
  }
  if (gameState.gameMode === GameMode.Teams || !gameState.isPlayerPsychic) {
    pointers.push({ angle: gameState.pointerAngle, colour: "rgb(211,38,54)", secondaryColour: "rgb(167,30,43)", label: "" });
  }

  return <Dial isScoringZoneVisible={isScoringZoneVisible} isScreenOpen={gameState.isScreenOpen} scoringZoneAngle={gameState.scoringZoneAngle} pointers={pointers} />;
});

export default DialFromGameState;
