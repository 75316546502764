import React from "react";
import styled, { css, keyframes } from "styled-components";
import Divider from "@material-ui/core/Divider";
import gameState from "./store/gameState";
import { observer } from "mobx-react";
import { Button, IconButton, Badge, Popover } from "@material-ui/core";
import { GamePhase } from "./hubs/models/GamePhase";
import People from "@material-ui/icons/People";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useMediaQuery } from "beautiful-react-hooks";
import PlayerList from "./PlayerList";
import { TeamsGameRound } from "./hubs/models/Round";

const TeamDetails: React.FC<{ id: number; name: string }> = observer(({ id, name }) => {
  const isSmall = useMediaQuery("(max-width: 1000px)");

  const members = gameState.players.filter((p) => p.team === id);

  const handleJoinButtonClick = () => {
    gameState.joinTeam(id);
  };

  const handleLeaveButtonClick = () => {
    gameState.leaveTeam();
  };

  const round = gameState.round as TeamsGameRound;
  const roundScore = id === 1 ? round?.team1Score : round?.team2Score;

  const isWinningTeam = gameState.phase === GamePhase.GameOver && ((id === 1 && gameState.team1Score > gameState.team2Score) || (id === 2 && gameState.team2Score > gameState.team1Score));
  const isActiveTeam = gameState.activeTeam === id;
  const isPlayersTeam = gameState.player?.team === id;

  const teamMemberList = (
    <div style={{ width: "100%" }}>
      <PlayerList players={members} />
      {members.length < 2 && <WaitingForPlayersMessage>Waiting for {2 - members.length} more team members...</WaitingForPlayersMessage>}
    </div>
  );
  return (
    <TeamContainer isWinningTeam={isWinningTeam} isActiveTeam={isActiveTeam}>
      <div>
        <Name>
          <span>
            {name} {isPlayersTeam && "^"}
          </span>
          {isSmall && (
            <PopupState variant="popover" popupId="demoPopover">
              {(popupState) => (
                <React.Fragment>
                  <IconButton {...bindTrigger(popupState)}>
                    <Badge badgeContent={members.length} color="secondary">
                      <People />
                    </Badge>
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div>{teamMemberList}</div>
                  </Popover>
                </React.Fragment>
              )}
            </PopupState>
          )}
        </Name>
        <Score>{id === 1 ? gameState.team1Score : gameState.team2Score}</Score>
        <RoundScore isVisible={gameState.phase === GamePhase.Scoring}>
          {roundScore || 0} point{roundScore !== 1 ? "s" : ""}!
        </RoundScore>
        <StyledDivider light />
        {!isSmall && teamMemberList}
        {!gameState.player?.team && (
          <Button color="primary" fullWidth onClick={handleJoinButtonClick} disabled={gameState.loadingTracker.isBusy}>
            Join Team
          </Button>
        )}
        {gameState.phase === GamePhase.Setup && gameState.player?.team === id && (
          <Button color="secondary" fullWidth onClick={handleLeaveButtonClick} disabled={gameState.loadingTracker.isBusy}>
            Leave Team
          </Button>
        )}
        {gameState.canGuess && gameState.player?.team === id && (
          <FinalizeGuessButton
            fullWidth
            color="primary"
            onClick={!gameState.actionCountdown ? gameState.finalizeGuess : gameState.cancelGuess}
            variant="contained"
            disabled={gameState.loadingTracker.isBusy && !gameState.actionCountdown}
          >
            {gameState.actionCountdown ? `Cancel guess ... ${gameState.actionCountdown}` : "Finalize Guess"}
          </FinalizeGuessButton>
        )}
        {isWinningTeam && <WinningTeamMessage>Winners!</WinningTeamMessage>}
      </div>
    </TeamContainer>
  );
});

export default TeamDetails;

const TeamContainer = styled.div<{ isWinningTeam: boolean; isActiveTeam: boolean }>`
  height: 100%;
  background-color: rgba(14, 64, 85, 0.3);
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;

  > div {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 16px;

    ${(props) =>
      props.isWinningTeam &&
      css`
        border: 2px solid goldenrod;
      `}

    ${(props) =>
      props.isActiveTeam &&
      css`
        box-shadow: inset 0 0 10px goldenrod !important;
      `}

      
  @media screen and (max-width: 1000px) {
      padding: 8px;
    }
  }
`;

const Name = styled.div`
  padding: 24px;
  padding-bottom: 0;

  @media screen and (max-width: 1000px) {
    padding: 0px;
    font-size: 11px;
  }
`;

const Score = styled.div`
  font-size: 60px;
  padding: 0 24px;

  @media screen and (max-width: 1000px) {
    font-size: 30px;
    padding: 0 4px;
    line-height: 1;
  }
`;

const bubble = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }

  70% {
    transform: scale(2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const RoundScore = styled.div<{ isVisible: boolean }>`
  display: inline-block;
  opacity: 0;
  transition: opacity 0.3s;
  padding-bottom: 16px;
  ${(props) =>
    props.isVisible &&
    css`
      animation: ${bubble} 1.2s linear 1;
      opacity: 1;
    `}
  @media screen and (max-width: 1000px) {
    padding: 0px;
    font-size: 10px;
    height: 0;
    position: relative;
    top: -6px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 10px;
  width: 100%;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const FinalizeGuessButton = styled(Button)`
  margin-top: 4px;
`;

const WinningTeamMessage = styled.div`
  color: gold;
`;

const WaitingForPlayersMessage = styled.div`
  margin: 4px 0;
`;
