import React from "react";
import PlayerList from "./PlayerList";
import gameState from "./store/gameState";
import { Typography, useMediaQuery, IconButton, Badge, Popover } from "@material-ui/core";
import styled from "styled-components";
import { observer } from "mobx-react";
import { GamePhase } from "./hubs/models/GamePhase";
import orderBy from "lodash/orderBy";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check";
import History from "@material-ui/icons/History";
import { usePopupState } from "material-ui-popup-state/hooks";
import RoundHistory from "./RoundHistory";

const IndividualsGamePlayers: React.FC = observer(() => {
  const winner = gameState.phase === GamePhase.GameOver ? orderBy(gameState.players, "score", "desc")[0] : null;
  const isSmall = useMediaQuery("(max-width: 1000px)");

  const popupState = usePopupState({ variant: "popover", popupId: "historyPopup" });

  return (
    <IndividualsGamePlayersContainer>
      <Header style={{ marginBottom: isSmall ? "0" : "8px" }}>
        <Typography variant="h5" style={{ marginRight: "8px" }}>
          Players
        </Typography>
        {isSmall && (
          <PopupState variant="popover" popupId="demoPopover">
            {(popupState) => (
              <React.Fragment>
                <IconButton {...bindTrigger(popupState)}>
                  <Badge badgeContent={gameState.players.length} color="primary">
                    <People />
                  </Badge>
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <PopoverContent>
                    <PlayerList players={gameState.players} />
                  </PopoverContent>
                </Popover>
              </React.Fragment>
            )}
          </PopupState>
        )}
        <IconButton {...bindTrigger(popupState)}>
          <History />
        </IconButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <RoundHistory />
        </Popover>
        {gameState.phase === GamePhase.Team && (
          <React.Fragment>
            <div style={{ flex: 1 }} />
            <span style={{ marginRight: "4px" }}>
              {gameState.guessLockedCount}/{gameState.players.length - 1}
            </span>
            <Check />
          </React.Fragment>
        )}
      </Header>

      {!isSmall && <PlayerList players={gameState.players} />}
      {gameState.phase === GamePhase.GameOver && (
        <WinnerMessage>
          <Typography variant="h6">{`${winner?.name} is the winner!`}</Typography>
        </WinnerMessage>
      )}
    </IndividualsGamePlayersContainer>
  );
});

export default IndividualsGamePlayers;

const IndividualsGamePlayersContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: rgba(14, 64, 85, 0.3);
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;

  @media screen and (max-width: 1000px) {
    padding: 8px;
  }
`;

const WinnerMessage = styled.div`
  margin: 8px 0;
`;

const PopoverContent = styled.div`
  min-width: 200px;
  padding: 0 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
