import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Typography } from "@material-ui/core";
import gameState from "./store/gameState";
import { observer } from "mobx-react";
import { GameMode } from "./hubs/models/GamePhase";

const CreateGameForm: React.FC = observer(() => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data: Record<string, any>) => {
    gameState.createGame(data["playerName"], gameMode as GameMode);
  };

  const [gameMode, setGameMode] = useState<GameMode | string>(GameMode.Teams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledTextField
        label="Enter Your Name"
        variant="outlined"
        margin="dense"
        fullWidth
        name="playerName"
        inputRef={register({ required: true, maxLength: 20 })}
        error={!!errors.playerName}
        autoComplete="off"
        disabled={gameState.loadingTracker.isBusy}
        autoFocus
      />
      <RadioButtons>
        <Typography variant="caption" display="block" style={{ lineHeight: 1 }}>
          Play as
        </Typography>
        <RadioGroup aria-label="gender" name="gender1" value={gameMode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGameMode((event.target as HTMLInputElement).value)}>
          <HorizontalRadioButtons>
            <FormControlLabel value={GameMode.Teams} control={<Radio color="primary" disabled={gameState.loadingTracker.isBusy} />} label={GameMode.Teams} />
            <FormControlLabel value={GameMode.Individuals} control={<Radio color="primary" disabled={gameState.loadingTracker.isBusy} />} label={GameMode.Individuals} />
          </HorizontalRadioButtons>
        </RadioGroup>
      </RadioButtons>
      <Button type="submit" variant="contained" color="primary" fullWidth disabled={gameState.loadingTracker.isBusy}>
        Create Game
      </Button>
    </form>
  );
});

export default CreateGameForm;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 16px;
  }
`;

const RadioButtons = styled.div`
  margin-bottom: 4px;
`;

const HorizontalRadioButtons = styled.div`
  display: flex;
  flex-direction: row;
`;
