import React from "react";
import Slider from "@material-ui/core/Slider";
import styled from "styled-components";
import { observer } from "mobx-react";
import gameState from "./store/gameState";
import { Button } from "@material-ui/core";
import { GamePhase, GameMode } from "./hubs/models/GamePhase";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { TeamsGameRound } from "./hubs/models/Round";

const Guess: React.FC = observer(() => {
  const handleChange = (_: any, newValue: number | number[]) => {
    gameState.setGuess(newValue as number);
  };

  const handleLeftRightButtonClick = (direction: string) => () => {
    gameState.guessLeftRight(direction);
  };

  const leftRightGuess = (gameState.round as TeamsGameRound)?.leftRightGuess;

  return (
    <GuessContainer>
      <SliderContainer>
        <StyledSlider
          value={gameState.guess}
          onChange={handleChange}
          min={-1}
          max={1}
          step={0.005}
          disabled={!gameState.canGuess || gameState.loadingTracker.isBusy || gameState.actionCountdown !== null}
        />
      </SliderContainer>
      {gameState.gameMode === GameMode.Individuals && (
        <FinalizeGuessButton
          color="primary"
          variant="contained"
          disabled={!gameState.canGuess || gameState.loadingTracker.isBusy || gameState.actionCountdown !== null}
          onClick={gameState.finalizeGuess}
        >
          Finalize Guess
        </FinalizeGuessButton>
      )}
      {gameState.gameMode !== GameMode.Individuals && (
        <LeftRightGuessContainer>
          <Button
            color="primary"
            variant={leftRightGuess === "left" || gameState.phase === GamePhase.LeftRight ? "contained" : "text"}
            disabled={gameState.phase !== GamePhase.LeftRight || gameState.isPlayerOnGuessingTeam || gameState.loadingTracker.isBusy}
            onClick={gameState.actionCountdown === null ? handleLeftRightButtonClick("left") : gameState.cancelLeftRightGuess}
          >
            {gameState.actionCountdown === null || gameState.phase !== GamePhase.LeftRight ? (
              <React.Fragment>
                <ArrowLeft />
                Left
              </React.Fragment>
            ) : (
              `Cancel guess ... ${gameState.actionCountdown}`
            )}
          </Button>
          <span style={{ margin: "0 20px" }}>TARGET</span>
          <Button
            color="primary"
            variant={leftRightGuess === "right" || gameState.phase === GamePhase.LeftRight ? "contained" : "text"}
            disabled={gameState.phase !== GamePhase.LeftRight || gameState.isPlayerOnGuessingTeam || gameState.loadingTracker.isBusy}
            onClick={gameState.actionCountdown === null ? handleLeftRightButtonClick("right") : gameState.cancelLeftRightGuess}
          >
            {gameState.actionCountdown === null || gameState.phase !== GamePhase.LeftRight ? (
              <React.Fragment>
                Right
                <ArrowRight />
              </React.Fragment>
            ) : (
              `Cancel guess ... ${gameState.actionCountdown}`
            )}
          </Button>
        </LeftRightGuessContainer>
      )}
    </GuessContainer>
  );
});

export default Guess;

const GuessContainer = styled.div`
  margin: 4px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftRightGuessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderContainer = styled.div`
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  &.MuiSlider-root:not(.Mui-disabled) {
    color: rgb(109, 181, 178);
  }

  .MuiSlider-track,
  .MuiSlider-rail {
    height: 10px;
    opacity: 1;
  }

  &.Mui-disabled .MuiSlider-track {
    opacity: 0;
  }
  &.Mui-disabled .MuiSlider-rail {
    opacity: 0.38;
  }

  .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    margin-top: -7px;
    margin-left: -12px;
    &:not(.Mui-disabled) {
      background-color: rgb(211, 38, 54);
    }
  }
`;

const FinalizeGuessButton = styled(Button)`
  margin-top: 8px;
`;
