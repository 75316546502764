import React from "react";
import { observer } from "mobx-react";
import gameState from "./store/gameState";
import { Typography, Grid } from "@material-ui/core";
import styled from "styled-components";
import Dial from "./Dial";
import { IndividualsGameRound } from "./hubs/models/Round";
import { PointerProps } from "./Dial";
import Person from "@material-ui/icons/Person";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";

const RoundHistory: React.FC = observer(() => {
  const rounds = gameState.history.map((round) => {
    let pointers: PointerProps[] = [];
    let scores = "";
    if ((round as IndividualsGameRound).playerGuesses) {
      const individualsGameRound = round as IndividualsGameRound;
      Object.keys(individualsGameRound.playerGuesses).forEach((playerKey) => {
        const playerGuess = individualsGameRound.playerGuesses[playerKey];
        pointers.push({
          angle: gameState.calculatePointerAngle(gameState.getPlayerGuess(playerKey, round)?.guess),
          colour: "rgb(211,38,54, 0.75)",
          secondaryColour: "rgb(167,30,43, 1)",
          label: playerGuess.playerName,
        });
      });

      scores = individualsGameRound.playerScores
        .map((playerScore) => {
          return `${playerScore.playerName}: ${playerScore.score} point${playerScore.score !== 1 ? "s" : ""}`;
        })
        .join(", ");
    }
    return (
      <div key={round.categoryCard.id}>
        <Typography variant="h6">
          {round.categoryCard.left.text} / {round.categoryCard.right.text}
        </Typography>
        <Grid container alignItems="center">
          <QuestionAnswer style={{ marginRight: "4px" }} />
          <Typography variant="subtitle1">{round.clue}!</Typography>
        </Grid>
        <Grid container alignItems="center">
          <Person style={{ marginRight: "4px" }} fontSize="small" />
          <Typography variant="subtitle2">{round.psychic}</Typography>
        </Grid>
        <DialContainer>
          <Dial scoringZoneAngle={round.target.public * 83} isScreenOpen={true} isScoringZoneVisible={true} pointers={pointers} />
        </DialContainer>
        <Typography>{scores}</Typography>
        <hr />
      </div>
    );
  });
  return (
    <RoundHistoryContainer>
      <Typography variant="h5" gutterBottom>
        Round History
      </Typography>
      <hr />
      {rounds}
      {!gameState.history.length && <Typography>No rounds have been completed yet</Typography>}
    </RoundHistoryContainer>
  );
});

export default RoundHistory;

const RoundHistoryContainer = styled.div`
  padding: 8px;
`;

const DialContainer = styled.div`
  padding: 8px 0;
  height: 250px;
  width: 500px;

  @media screen and (max-width: 1000px) {
    height: 175px;
    width: 350px;
  }
`;
