import React from "react";
import logo from "./assets/logo.png";
import styled from "styled-components";
import { usePopupState } from "material-ui-popup-state/hooks";
import gameState from "./store/gameState";
import { observer } from "mobx-react";
import notificationStore from "./store/notificationStore";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Menu from "@material-ui/icons/Menu";
import Share from "@material-ui/icons/Share";
import ExitToApp from "@material-ui/icons/ExitToApp";
import CopyToClipboard from "react-copy-to-clipboard";

const Navbar: React.FC = observer(() => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover"
  });

  const onCopiedInviteToClipboard = () => {
    notificationStore.info("Copied invite url to clipboard!");
    popupState.close();
    setIsMenuOpen(false);
  };

  const leaveGame = async () => {
    await gameState.leaveGame();
    setIsMenuOpen(false);
  };

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const inviteUrl = `${window.location.origin}/${gameState.gameId}`;

  return (
    <NavbarContainer>
      <Logo src={logo} alt="Wavelength logo" />
      <div style={{ flex: 1 }} />
      <StyledSpeedDial ariaLabel="Menu" direction="down" FabProps={{ size: "small" }} icon={<Menu />} onClose={handleMenuClose} onOpen={handleMenuOpen} open={isMenuOpen}>
        <CopyToClipboard text={inviteUrl} onCopy={onCopiedInviteToClipboard}>
          <SpeedDialAction icon={<Share />} tooltipTitle="Invite Players" tooltipOpen />
        </CopyToClipboard>
        {!gameState.isPlayerHost && <SpeedDialAction icon={<ExitToApp />} tooltipTitle="Leave Game" tooltipOpen onClick={leaveGame} />}
      </StyledSpeedDial>
    </NavbarContainer>
  );
});

export default Navbar;

const NavbarContainer = styled.div`
  position: relative;
  line-height: 1;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1000px) {
    height: 36px;
  }
`;

const Logo = styled.img`
  height: 29px;

  @media screen and (max-width: 1000px) {
    height: 24px;
  }
`;

const StyledSpeedDial = styled(SpeedDial)`
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;

  .MuiFab-sizeSmall {
    height: 36px;
    width: 36px;
  }
  .MuiSvgIcon-root {
    font-size: 1.4em;
  }
  .MuiSpeedDialAction-staticTooltipLabel {
    white-space: nowrap;
    background-color: black;
  }
`;
