import React from "react";
import styled from "styled-components";
import arrowLeft from "./assets/arrow-left.svg";
import { observer } from "mobx-react";
import gameState from "./store/gameState";

const CategoryCard: React.FC = observer(() => {
  const card = gameState.round?.categoryCard || {
    left: {
      text: "?",
      colour: "rgb(62,163,138)",
    },
    right: {
      text: "?",
      colour: "rgb(235,190,192)",
    },
  };
  return (
    <CardContainer>
      <CardLeft background={card.left.colour}>
        <ArrowLeft src={arrowLeft} alt="arrow" />
        <div>{card.left.text}</div>
      </CardLeft>
      <CardRight background={card.right.colour}>
        <ArrowRight src={arrowLeft} alt="arrow" />
        {card.right.text}
      </CardRight>
    </CardContainer>
  );
});

export default CategoryCard;

const CardContainer = styled.div`
  width: 100%;
  max-width: 350px;
  height: 170px;
  display: grid;
  grid-template-areas: "left right";
  color: black;
  grid-template-columns: 1fr 1fr;
`;

const CardSide = styled.div<{ background: string }>`
  grid-area: left;
  background-color: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;
  font-size: 1.2em;
  text-align: center;
`;

const CardLeft = styled(CardSide)`
  grid-area: left;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const CardRight = styled(CardSide)`
  grid-area: right;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Arrow = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;

const ArrowLeft = styled(Arrow)``;

const ArrowRight = styled(Arrow)`
  transform: rotate(180deg);
`;
