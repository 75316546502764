import React from "react";
import { observer } from "mobx-react";
import { IconButton } from "@material-ui/core";
import styled, { css } from "styled-components";
import { Player } from "./hubs/models/Player";
import gameState from "./store/gameState";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Person from "@material-ui/icons/Person";
import Check from "@material-ui/icons/Check";
import Star from "@material-ui/icons/Star";
import { GameMode, GamePhase } from "./hubs/models/GamePhase";

interface PlayerListProps {
  players: Player[];
}

const PlayerList: React.FC<PlayerListProps> = observer(({ players }) => {
  const hasPlayerLockedGuess = (player: Player) => {
    return gameState.getPlayerGuess(player.name, gameState.round!)?.isLocked;
  };

  const playerComponents = players.map((player) => {
    let playerIcon = <PlayerIcon />;

    if (player.isHost && gameState.phase === GamePhase.Setup) {
      playerIcon = (
        <PlayerIcon>
          <Star />
        </PlayerIcon>
      );
    }

    if (hasPlayerLockedGuess(player)) {
      playerIcon = (
        <PlayerIcon>
          <Check />
        </PlayerIcon>
      );
    }

    if (player.isPsychic) {
      playerIcon = (
        <PlayerIcon>
          <Person />
        </PlayerIcon>
      );
    }

    return (
      <PlayerListItem key={player.name} title={!player.isConnected ? "Offline" : ""}>
        {playerIcon}
        <PlayerName isPlayer={player.name === gameState.playerName} isConnected={player.isConnected}>
          {player.name}
        </PlayerName>

        {gameState.gameMode === GameMode.Individuals && <span>{player.score}</span>}

        {gameState.isPlayerHost && (
          <IconButton title="Kick Player" size="small" onClick={() => gameState.bootPlayer(player.name)} disabled={gameState.loadingTracker.isBusy || player.name === gameState.playerName}>
            <ExitToApp fontSize="small" />
          </IconButton>
        )}
      </PlayerListItem>
    );
  });

  return <PlayerListContainer>{playerComponents}</PlayerListContainer>;
});

export default PlayerList;

const PlayerListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlayerListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
`;

const PlayerName = styled.div<{ isPlayer: boolean; isConnected: boolean }>`
  flex: 1;
  ${(props) =>
    props.isPlayer &&
    css`
      color: goldenrod;
    `}
  ${(props) =>
    !props.isConnected &&
    css`
      color: #888;
    `}
`;

const PlayerIcon = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
