import * as signalR from "@microsoft/signalr";
import { observable, extendObservable } from "mobx";
import SubscribableEvent from "subscribableevent";
import { ServerGameActionResponse } from "./models/ServerGameActionResponse";
import { ServerGameStateModel } from "./models/ServerGameStateModel";
import { GameMode } from "./models/GamePhase";

const reconnetPolicy = {
  nextRetryDelayInMilliseconds: (retryContext: signalR.RetryContext) => {
    const delay = [0, 2000, 10000];
    if (retryContext.previousRetryCount < delay.length) {
      return delay[retryContext.previousRetryCount];
    }
    return delay[delay.length - 1];
  },
} as signalR.IRetryPolicy;

export class GameHub {
  @observable startPromise: Promise<void>;
  connection: signalR.HubConnection;
  onServerGameStateUpdated = new SubscribableEvent<(payload: ServerGameStateModel) => void>();
  onYouveBeenBooted = new SubscribableEvent<() => void>();
  onNotification = new SubscribableEvent<(payload: string) => void>();
  onReconnected = new SubscribableEvent();
  private resolveStartPromise: (value?: void | PromiseLike<void> | undefined) => void = () => {};

  constructor() {
    const url = `${process.env.REACT_APP_API_URL}/game`;

    const connection = new signalR.HubConnectionBuilder().withUrl(url).withAutomaticReconnect(reconnetPolicy).build();

    extendObservable(connection, {
      connectionState: (connection as any).connectionState,
      get state() {
        return (this as any).connectionState;
      },
    });

    this.connection = connection;

    this.connection.onreconnected(() => this.onReconnected.fire());

    this.startPromise = new Promise((resolve) => (this.resolveStartPromise = resolve));
  }

  async start() {
    try {
      if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
        return;
      }

      this.connection.on("GameStateUpdated", (serverGameState: ServerGameStateModel) => {
        this.onServerGameStateUpdated.fire(serverGameState);
      });

      this.connection.on("YouveBeenBooted", () => {
        this.onYouveBeenBooted.fire();
      });

      this.connection.on("Notification", (message: string) => {
        this.onNotification.fire(message);
      });

      await this.connection.start();
      this.resolveStartPromise();
    } catch (error) {
      console.error(error);
      setTimeout(() => this.start(), 5000);
    }
  }

  async ensureStarted() {
    if (!this.startPromise || !this.connection) await this.start();
    else await this.startPromise;
  }

  async createGame(playerName: string, gameMode: GameMode) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("CreateGame", playerName, gameMode);
  }

  async joinGame(playerName: string, gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("JoinGame", playerName, gameId);
  }

  async leaveGame(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("LeaveGame", gameId);
  }

  async joinTeam(gameId: string, teamId: number) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("JoinTeam", gameId, teamId);
  }

  async leaveTeam(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("LeaveTeam", gameId);
  }

  async getTarget(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<number>("GetTarget", gameId);
  }

  async setClue(gameId: string, clue: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("SetClue", gameId, clue);
  }

  async setGuess(gameId: string, guess: number) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("SetGuess", gameId, guess);
  }

  async finalizeGuess(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("FinalizeGuess", gameId);
  }

  async cancelGuess(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("CancelGuess", gameId);
  }

  async guessLeftRight(gameId: string, direction: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("GuessLeftRight", gameId, direction);
  }

  async cancelLeftRightGuess(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("CancelGuessLeftRight", gameId);
  }

  async startGame(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("StartGame", gameId);
  }

  async startNextRound(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("StartNextRound", gameId);
  }

  async startNewGame(gameId: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("StartNewGame", gameId);
  }

  async bootPlayer(gameId: string, playerName: string) {
    await this.ensureStarted();
    return this.connection!.invoke<ServerGameActionResponse>("BootPlayer", gameId, playerName);
  }
}

const gameHub = new GameHub();

export default gameHub;
