import React from "react";
import Dialog from "@material-ui/core/Dialog";
import gameState from "./store/gameState";
import { observer } from "mobx-react";
import styled from "styled-components";
import JoinGameForm from "./JoinGameForm";
import { Typography } from "@material-ui/core";

interface JoinGameDialogProps {
  isOpen: boolean;
}
const JoinGameDialog: React.FC<JoinGameDialogProps> = observer(({ isOpen }) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Typography variant="h5">Welcome to Wavelength!</Typography>
        <Typography variant="body1">To join the game, enter your name below and click 'Join Game'.</Typography>
        <FlexSpacer></FlexSpacer>
        <SectionForm>
          <JoinGameForm gameId={gameState.inviteGameId!} />
        </SectionForm>
      </DialogContent>
    </Dialog>
  );
});

export default JoinGameDialog;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
`;

const SectionForm = styled.div`
  align-self: flex-end;
  width: 100%;
`;

const FlexSpacer = styled.div`
  flex: 1;
`;
