import React from "react";
import { useForm } from "react-hook-form";
import gameState from "./store/gameState";
import { TextField, Button } from "@material-ui/core";
import styled from "styled-components";
import { observer } from "mobx-react";

const ClueForm: React.FC = observer(() => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data: Record<string, any>) => {
    gameState.setClue(data["clue"]);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HorizontalForm>
        <StyledTextField
          label="Enter a clue..."
          variant="outlined"
          margin="dense"
          fullWidth
          name="clue"
          inputRef={register({ required: true, maxLength: 100 })}
          error={!!errors.clue}
          autoComplete="off"
          disabled={gameState.loadingTracker.isBusy}
        />
        <StyledButton type="submit" variant="contained" color="primary" disabled={gameState.loadingTracker.isBusy}>
          Give Clue
        </StyledButton>
      </HorizontalForm>
    </form>
  );
});

export default ClueForm;

const HorizontalForm = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTextField = styled(TextField)`
  margin: 0;
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;
