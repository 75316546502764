import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";
import gameState from "./store/gameState";
import { observer } from "mobx-react";

interface JoinGameFormProps {
  gameId?: string | null;
}

const JoinGameForm: React.FC<JoinGameFormProps> = observer(({ gameId }) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data: Record<string, any>) => {
    await gameState.joinGame(data["playerName"], data["gameId"]);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledTextField
          label="Enter Your Name"
          variant="outlined"
          margin="dense"
          fullWidth
          name="playerName"
          inputRef={register({ required: true, maxLength: 20 })}
          error={!!errors.playerName}
          autoComplete="off"
          autoFocus
          disabled={gameState.loadingTracker.isBusy}
        />
        <StyledTextField
          value={gameId}
          label="Game Id"
          variant="outlined"
          margin="dense"
          fullWidth
          name="gameId"
          inputRef={register({ required: true, maxLength: 32 })}
          disabled={gameState.loadingTracker.isBusy || !!gameId}
          error={!!errors.gameName}
          autoComplete="off"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={gameState.loadingTracker.isBusy}>
          Join Game
        </Button>
      </form>
    </React.Fragment>
  );
});

export default JoinGameForm;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 16px;
  }
`;
