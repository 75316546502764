import React, { useEffect } from "react";
import styled from "styled-components";
import CategoryCard from "./CategoryCard";
import Dial from "./DialFromGameState";
import TeamDetails from "./TeamDetails";
import Guess from "./Guess";
import GameStateMessage from "./GameStateMessage";
import gameHub from "./hubs/GameHub";
import CreateGameDialog from "./CreateGameDialog";
import { CircularProgress, Backdrop, Typography, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import gameState from "./store/gameState";
import Navbar from "./Navbar";
import Notifications from "./Notifications";
import JoinGameDialog from "./JoinGameDialog";
import { GameMode } from "./hubs/models/GamePhase";
import IndividualsGamePlayers from "./IndividualsGamePlayers";
import RoundScore from "./RoundScore";

const App: React.FC = observer(() => {
  useEffect(() => {
    const startHub = async () => {
      await gameHub.start();
    };

    startHub();
  }, []);

  const isDisconnected = gameState.gameHub.connection !== undefined && gameState.gameHub.connection.state !== "Connected";

  return (
    <React.Fragment>
      <Stars>
        <div id="stars"></div>
        <div id="stars1"></div>
        <div id="stars2"></div>
      </Stars>
      <StyledBackdrop open={isDisconnected}>
        <Grid container direction="column" justify="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h5" gutterBottom>
            Connecting to server...
          </Typography>
        </Grid>
      </StyledBackdrop>
      {gameState.gameMode === GameMode.Teams && (
        <TeamsGameLayout>
          <NavbarContainer>
            <Navbar />
          </NavbarContainer>
          <Team1Container>
            <TeamDetails id={1} name="Left Brain" />
          </Team1Container>
          <Team2Container>
            <TeamDetails id={2} name="Right Brain" />
          </Team2Container>
          <DialContainer>
            <Dial />
          </DialContainer>
          <GuessContainer>
            <Guess />
          </GuessContainer>
          <CardContainer>
            <CategoryCard />
          </CardContainer>
          <GameStateMessageContainer>
            <GameStateMessage />
          </GameStateMessageContainer>
        </TeamsGameLayout>
      )}
      {gameState.gameMode === GameMode.Individuals && (
        <IndividualsGameLayout>
          <NavbarContainer>
            <Navbar />
          </NavbarContainer>
          <DialContainer>
            <Dial />
          </DialContainer>
          <GuessContainer>
            <Guess />
          </GuessContainer>
          <CardContainer>
            <CategoryCard />
          </CardContainer>
          <GameStateMessageContainer>
            <GameStateMessage />
          </GameStateMessageContainer>
          <PlayerListContainer>
            <IndividualsGamePlayers />
          </PlayerListContainer>
        </IndividualsGameLayout>
      )}
      <RoundScore />
      <CreateGameDialog isOpen={!gameState.isInGame && !gameState.inviteGameId} />
      <JoinGameDialog isOpen={!gameState.isInGame && !!gameState.inviteGameId} />
      <Notifications />
    </React.Fragment>
  );
});

export default App;

const Layout = styled.div`
  display: grid;
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 16px;
  grid-gap: 16px;
  color: white;

  @media screen and (max-width: 1000px) {
    padding: 8px;
    grid-gap: 8px;
  }
`;

const TeamsGameLayout = styled(Layout)`
  grid-template-areas:
    "team1 navbar team2"
    "team1 dial team2"
    "team1 guess team2"
    "team1 card team2"
    "team1 message team2";
  grid-template-columns: 200px 1fr 200px;
  grid-template-rows: min-content 1fr min-content min-content min-content;

  @media screen and (max-width: 1000px) {
    grid-template-areas:
      "navbar navbar"
      "team1 team2"
      "dial dial"
      "guess guess"
      "card card"
      "message message";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content 1fr min-content min-content min-content;
  }
`;

const IndividualsGameLayout = styled(Layout)`
  grid-template-areas:
    "navbar navbar"
    "players dial"
    "players guess"
    "players card"
    "players message";
  grid-template-columns: 200px 1fr;
  grid-template-rows: min-content 1fr min-content min-content min-content;
  @media screen and (max-width: 1000px) {
    grid-template-areas:
      "navbar"
      "dial"
      "guess"
      "card"
      "message"
      "players";
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content min-content min-content min-content;
  }
`;

const Team1Container = styled.div`
  grid-area: team1;
`;

const Team2Container = styled.div`
  grid-area: team2;
`;

const GameStateMessageContainer = styled.div`
  grid-area: message;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialContainer = styled.div`
  grid-area: dial;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardContainer = styled.div`
  grid-area: card;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GuessContainer = styled.div`
  grid-area: guess;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stars = styled.div`
  background-color: rgb(14, 16, 50);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

const StyledBackdrop = styled(Backdrop)`
  &.MuiBackdrop-root {
    z-index: 9999;
    color: white;
  }
`;

const NavbarContainer = styled.div`
  grid-area: navbar;
`;

const PlayerListContainer = styled.div`
  grid-area: players;
`;
