import React from "react";
import { observer } from "mobx-react";
import gameState from "./store/gameState";
import styled from "styled-components";
import { GamePhase } from "./hubs/models/GamePhase";
import { Fab } from "@material-ui/core";
import ClueForm from "./ClueForm";

const GameStateMessage: React.FC = observer(() => {
  let message = "";
  if (gameState.phase === GamePhase.Setup && !gameState.areTeamsValid) message = "Waiting for players...";
  const canGameStart = gameState.phase === GamePhase.Setup && gameState.areTeamsValid;
  if (canGameStart && !gameState.isPlayerHost) message = "Waiting for host to start game...";
  if (gameState.phase === GamePhase.Psychic && !gameState.isPlayerPsychic) message = `${gameState.psychicPlayerName} is thinking of a clue...`;
  if (gameState.phase === GamePhase.Team || gameState.phase === GamePhase.LeftRight || gameState.phase === GamePhase.Scoring || gameState.phase === GamePhase.GameOver)
    message = `${gameState.round?.clue!}!`;

  return (
    <GameStateMessageContainer>
      {message}
      {canGameStart && gameState.isPlayerHost && (
        <HostButton variant="extended" size="small" color="primary" onClick={gameState.startGane} disabled={gameState.loadingTracker.isBusy}>
          Start Game
        </HostButton>
      )}
      {gameState.isPlayerPsychic && gameState.phase === GamePhase.Psychic && <ClueForm />}
      {gameState.phase === GamePhase.Scoring && gameState.isPlayerHost && (
        <HostButton variant="extended" size="small" color="primary" onClick={gameState.startNextRound} style={{ marginLeft: "10px" }} disabled={gameState.loadingTracker.isBusy}>
          Next Round
        </HostButton>
      )}
      {gameState.phase === GamePhase.GameOver && gameState.isPlayerHost && (
        <HostButton variant="extended" size="small" color="primary" onClick={gameState.startNewGame} disabled={gameState.loadingTracker.isBusy}>
          New Game
        </HostButton>
      )}
    </GameStateMessageContainer>
  );
});

const GameStateMessageContainer = styled.span`
  font-style: italic;
  font-size: 1.5em;
  line-height: 34px;
`;

const HostButton = styled(Fab)`
  margin-left: 10px;
`;

export default GameStateMessage;
