import { action, observable } from "mobx";

export enum NotificationLevel {
  info = "info",
  warning = "warning",
  error = "error",
  success = "success"
}

export interface Notification {
  key: number;
  createdAt: Date;
  message: string;
  level: NotificationLevel;
  isOpen: boolean;
}

export class NotificationStore {
  @observable notifications: Notification[] = [];

  @action.bound info(message: string) {
    this.notifications.push(this.createNotification(message, NotificationLevel.info));
  }

  @action.bound warning(message: string) {
    this.notifications.push(this.createNotification(message, NotificationLevel.warning));
  }

  @action.bound error(message: string) {
    this.notifications.push(this.createNotification(message, NotificationLevel.error));
  }

  @action.bound success(message: string) {
    this.notifications.push(this.createNotification(message, NotificationLevel.success));
  }

  private createNotification(message: string, level: NotificationLevel) {
    return { key: new Date().getTime() + Math.random(), message, level: level, isOpen: false, createdAt: new Date() };
  }

  @action.bound remove(notification: Notification) {
    const index = this.notifications.indexOf(notification);
    this.notifications = this.notifications.splice(index, 1);
  }
}

const notificationStore = new NotificationStore();

(window as any).notificationStore = notificationStore;

export default notificationStore;
