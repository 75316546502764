import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#a4d9cf",
      main: "#a4d9cf",
      dark: "#a4d9cf"
    }
  },
  typography: {
    fontFamily: "NeutraText-Book"
  }
});

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
