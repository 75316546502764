export enum GamePhase {
  Setup = "Setup",
  Psychic = "Psychic",
  Team = "Team",
  LeftRight = "LeftRight",
  Scoring = "Scoring",
  GameOver = "GameOver",
}

export enum GameMode {
  Teams = "Teams",
  Individuals = "Individuals",
}
