import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import notificationStore from "./store/notificationStore";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

const Notifications: React.FC<WithSnackbarProps> = observer(({ enqueueSnackbar, closeSnackbar }) => {
  useEffect(() => {
    return autorun(() => {
      notificationStore.notifications.forEach(notification => {
        if (notification.isOpen) return;

        enqueueSnackbar(notification.message, {
          key: notification.key,
          variant: notification.level,
          onClose: () => {
            notificationStore.remove(notification);
          },
          action: () => (
            <IconButton
              size="small"
              onClick={() => {
                closeSnackbar(notification.key);
              }}
            >
              <Close />
            </IconButton>
          )
        });

        notification.isOpen = true;
      });
    });
  }, [closeSnackbar, enqueueSnackbar]);

  return null;
});

export default withSnackbar(Notifications);
