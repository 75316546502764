import React, { useState, useEffect } from "react";
import { Drawer, Typography } from "@material-ui/core";
import { reaction } from "mobx";
import gameState from "./store/gameState";
import { GamePhase, GameMode } from "./hubs/models/GamePhase";
import styled from "styled-components";
import { IndividualsGameRound } from "./hubs/models/Round";

const RoundScore: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return reaction(
      () => gameState.phase,
      (phase) => {
        if (phase === GamePhase.Scoring && gameState.gameMode === GameMode.Individuals) {
          setIsOpen(true);
        }
        if (phase === GamePhase.Psychic) {
          setIsOpen(false);
        }
      }
    );
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const round = gameState.round as IndividualsGameRound;
  const scores = round?.playerScores?.map((ps) => (
    <Typography variant="h5">
      {ps.playerName}: {ps.score}
    </Typography>
  ));

  return (
    <React.Fragment>
      <StyledDrawer anchor="bottom" open={isOpen} onClose={onClose}>
        <RoundScoreContent>
          <Typography variant="h4" gutterBottom>
            Scores
          </Typography>
          {scores}
        </RoundScoreContent>
      </StyledDrawer>
    </React.Fragment>
  );
};

export default RoundScore;

const RoundScoreContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`;

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;
