import styled from "styled-components";
import React, { useRef, useState, useEffect, useCallback, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { useMediaQuery } from "@material-ui/core";

export interface DialProps {
  isScoringZoneVisible: boolean;
  scoringZoneAngle: number;
  pointers: PointerProps[];
  isScreenOpen: boolean;
}

export interface PointerProps {
  angle: number;
  colour: string;
  secondaryColour: string;
  label: string;
}

const Dial: React.FC<DialProps> = observer(({ isScoringZoneVisible, scoringZoneAngle, isScreenOpen, pointers }) => {
  const isSmall = useMediaQuery("(max-width: 1000px)");
  const divEl = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = () => {
    return {
      width: divEl.current ? divEl.current.offsetWidth : 0,
      height: divEl.current ? divEl.current.offsetHeight : 0,
    };
  };

  const setDimensionsIfChanged = useCallback(() => {
    if (divEl.current) {
      if (dimensions.width !== divEl.current.offsetWidth || dimensions.height !== divEl.current.offsetHeight)
        setDimensions({
          width: divEl.current.offsetWidth,
          height: divEl.current.offsetHeight,
        });
    }
  }, [dimensions.height, dimensions.width]);

  useLayoutEffect(() => setDimensionsIfChanged(), [setDimensionsIfChanged]);

  useEffect(() => {
    const cancel = window.setInterval(() => {
      setDimensionsIfChanged();
    }, 2000);

    return () => window.clearInterval(cancel);
  }, [setDimensionsIfChanged]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DialContainer ref={divEl}>
      <SvgContainer width={dimensions.width} height={dimensions.height}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 2 1" width="100%" height="100%">
          <clipPath id="dialClip">
            <path d="M -0.9921147013144779 -0.12533323356430429 A 1 1 0 0 1 0.9921147013144778 -0.12533323356430465 L 0 0"></path>
          </clipPath>
          <g clipPath="url(#dialClip)">
            <path d="M 0 0 L -0.9921147013144779 -0.12533323356430429 A 1 1 0 0 1 0.9921147013144778 -0.12533323356430465 L 0 0" fill="rgb(244,237,225)"></path>
            <ScoringZone angle={scoringZoneAngle} isVisible={isScoringZoneVisible}>
              <path d="M -0.30901699437494756 -0.9510565162951535 A 1 1 0 0 1 -0.18738131458572463 -0.9822872507286887 L 0 0" fill="rgb(232,168,32)"></path>
              <path d="M -0.18738131458572463 -0.9822872507286887 A 1 1 0 0 1 -0.06279051952931321 -0.9980267284282716 L 0 0" fill="rgb(239,71,60)"></path>
              <path d="M -0.06279051952931321 -0.9980267284282716 A 1 1 0 0 1 0.06279051952931283 -0.9980267284282716 L 0 0" fill="rgb(90,131,155)"></path>
              <path d="M 0.06279051952931283 -0.9980267284282716 A 1 1 0 0 1 0.18738131458572427 -0.9822872507286887 L 0 0" fill="rgb(239,71,60)"></path>
              <path d="M 0.18738131458572427 -0.9822872507286887 A 1 1 0 0 1 0.30901699437494723 -0.9510565162951536 L 0 0" fill="rgb(232,168,32)"></path>
              <text style={{ fontSize: "0.1px" }} x="-0.245" y="-0.86" rotate="-14.4">
                2
              </text>
              <text style={{ fontSize: "0.1px" }} x="-0.14" y="-0.89" rotate="-7.2">
                3
              </text>
              <text style={{ fontSize: "0.1px" }} x="-0.03" y="-0.905">
                4
              </text>
              <text style={{ fontSize: "0.1px" }} x="0.08" y="-0.9" rotate="7.2">
                3
              </text>
              <text style={{ fontSize: "0.1px" }} x="0.2" y="-0.88" rotate="14.4">
                2
              </text>
            </ScoringZone>
            <Screen isOpen={isScreenOpen}>
              <path d="M 0 0 L -0.9921147013144779 -0.12533323356430429 A 1 1 0 0 1 0.9921147013144778 -0.12533323356430465 L 0 0" fill="rgb(109,181,178)"></path>
            </Screen>

            {pointers.map((pointer, index) => (
              <Pointer key={pointer.label} angle={pointer.angle}>
                <text style={{ fontSize: isSmall ? "0.06px" : "0.04px" }} x="0.02" y={index * 0.05 + -0.85} letterSpacing="0">
                  {pointer.label}
                </text>
                <line x1="0" y1="0" x2="0" y2="-0.85" stroke={pointer.colour} strokeWidth="0.025" strokeLinecap="round" />
                <circle r="0.2" fill={pointer.secondaryColour}></circle>
              </Pointer>
            ))}
          </g>
        </svg>
      </SvgContainer>
    </DialContainer>
  );
});

export default Dial;

const DialContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SvgContainer = styled.div.attrs<{ width: number; height: number }>((props) => ({ style: { height: `${props.height}px`, width: `${props.width}px` } }))<{ width: number; height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Screen = styled.g<{ isOpen: boolean }>`
  transition: transform 1s;
  ${(props) =>
    props.isOpen &&
    `
    transform: rotate(166deg);
  `}
`;

const Pointer = styled.g.attrs<{ angle: number }>((props) => ({ style: { transform: `rotate(${props.angle}deg)` } }))<{ angle: number }>``;

const ScoringZone = styled.g.attrs<{ angle: number; isVisible: boolean }>((props) => ({ style: { transform: `rotate(${props.angle}deg)`, opacity: props.isVisible ? "1" : "0" } }))<{
  angle: number;
  isVisible: boolean;
}>`
  transition: transform 0.3s;
`;
