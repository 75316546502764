import React from "react";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import CreateGameForm from "./CreateGameForm";
import { Typography } from "@material-ui/core";

export interface CreateGameDialogProps {
  isOpen: boolean;
}

const CreateGameDialog: React.FC<CreateGameDialogProps> = ({ isOpen }) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Typography variant="h5">Welcome to Wavelength!</Typography>
        <Typography variant="body1">To create a game, enter your name below, choose whether to compete as teams or individual players and click 'Create Game'.</Typography>
        <Typography variant="body1">Once you're in the game, you'll be able to invite more players to get started.</Typography>
        <FlexSpacer></FlexSpacer>
        <FormContainer>
          <CreateGameForm />
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
};

export default CreateGameDialog;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
`;

const FormContainer = styled.div`
  align-self: flex-end;
  width: 100%;
`;

const FlexSpacer = styled.div`
  flex: 1;
`;
